import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types"
import Layout from './layout';
import Seo from './seo';
import WebinarRegistrationPopup from './Popup/webinar-registration-popup';
import { getDayOfMonth, getGTMOffsetStr, getMonthName, getTimeIn12Hours } from '../utils/utils';
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarPage = (props) => {
  const seo = {
    metaDesc: `Webinar ${props.title} - Inventiv.org`
  }
  const WEBINAR_URL = props.webinarUrl;
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_URL);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <Seo title={`Webinar ${props.title} - Inventiv.org`} canonical={props.canonical} seo={seo} />


      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'>{props.title}
            </h1>
          </div>
        </div>
      </div>

      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div className=''>
              <div className='row'>
                <div class=" text-center col-md-3 wow fadeInUp delay-04s">
                  <div className='circular_image' style={{ width: '8rem', height: '8rem' }}>
                    <img style={{ display: 'block', objectFit: 'cover', width: '8rem' }} src="https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Ftranbaov2.png" alt='An AI-Powered Patent Drafting Solution'></img>
                  </div>
                  <p>
                    <small>Presented by:</small>
                  </p>
                  <p>
                    <strong>Bao Tran</strong>
                  </p>
                  <p>
                    {props.introAuthor}
                  </p>

                </div>
                <div class="col-md-6 wow fadeInUp delay-04s">
                  <div className='my-element'>
                    <h3 className='bold text-center'>Here's what you'll learn:</h3>
                    {
                      props.lessons.map(m => {
                        return (
                          <div className='d-flex justify-content-start my-2'>
                            <h5 className='main-clr'>✓</h5>
                            <h5 className='ms-3'>
                              {m}
                            </h5>
                          </div>
                        )
                      })
                    }

                    <div>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>{props.btnTxt}</a>
                    </div>
                  </div>
                </div>
                <div class=" text-center col-md-3 wow fadeInUp delay-04s">
                  <div class="card border-0 shadow">
                    <h5 class="card-header bg-primary text-white text-uppercase"><strong>{getMonthName()}</strong></h5>
                    <div class="card-body">
                      <h5 class="card-title" style={{ fontSize: '60px' }}><strong>{getDayOfMonth()}</strong></h5>
                    </div>
                  </div>
                  <p className='mt-5' style={{ fontSize: '24px' }}>
                    <i class="fa fa-clock-o"></i> <span>  {getTimeIn12Hours()} {getGTMOffsetStr()}</span>
                  </p>
                  <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
    </Layout>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  webinarUrl: PropTypes.string,
  introAuthor: PropTypes.string,
  btnTxt: PropTypes.string,
  lessons: PropTypes.arrayOf(PropTypes.string)
}

export default WebinarPage