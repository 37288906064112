import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarLeveraging = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc38d6e2aace524737b14f?date=jit_15";
  const title = "E-commerce Domination Using Intellectual Property: Leverage Your IP for Success in 2025";
  const canonical = "/webinar-leveraging-intellectual-property-for-e-commerce-success";
  const introAuthor = "Bao Tran is an IP attorney specializing in online business models."
  const lessons = [
    "Integrating IP protection into e-commerce strategies.",
    "Tackling online brand misuse and counterfeit products.",
    "Building IP-driven business models for online marketplaces.",
    "Navigating the evolving world of digital sales and IP rights."
  ]
  const btnTxt = "Watch Webinar Now";
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarLeveraging;